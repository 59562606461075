@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&subset=latin-ext');
@import "./../../node_modules/bootstrap/scss/bootstrap";
@import "./../../node_modules/owl.carousel/src/scss/owl.carousel";
@import "./../../node_modules/owl.carousel/src/scss/owl.theme.default";
@import "./partials/_variables";
@import "./partials/_backgrounds";
@import "./partials/_paddings";
@import "./partials/_lists";
@import "./partials/_mixins";
@import "./partials/_buttons";
@import "./partials/_hamburger";
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$color: white;
html {
  font-size: 10px;
}

html,
body {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-size: 3rem;
  color: #004180;
  text-transform: uppercase;
  margin-bottom: 0;
}

p {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: black;
}

.form-control::-webkit-input-placeholder {
  color: $color;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: $color;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $color;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $color;
}

.form-control::-ms-input-placeholder {
  /* Edge */
  color: $color;
}

.form-control:placeholder-shown {
  /* Standard one last! */
  color: $color;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.spash-bg {
  background-size: cover;
  position: relative;
  background-position: center;
  top: 0;
  left: 0;
  // margin-top: -95px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  .logo {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0px 15px;
    display: block;
    margin: 0 auto;
    text-align: center;
    .row {
      // height: 75vh;
      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .text-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0 auto;
    z-index: 2;
    background-color: rgba(#004180, 0.9);
    text-align: center;
    >.row {
      height: 240px;
      min-height: 240px;
    }
    h2 {
      color: white;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 2.4rem;
      line-height: 1.5;
    }
    h3 {
      color: white;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 1.6rem;
      line-height: 1.5;
    }
    p {
      color: white;
      font-weight: 100;
      font-size: 1.8rem;
      margin-top: 15px;
      line-height: 1.5;
    }
    form {
      margin-top: 25px;
      label.error {
        font-size: 1rem;
        color: white;
        margin-top: 10px;
        text-transform: uppercase;
      }
    }
  }
}

.form-control {
  background-color: #2B629E;
  color: white;
  padding: 15px 20px;
  font-size: 1.4rem;
  border: 0px;
  border-radius: 0;
  letter-spacing: 1px;
  width: none;
  transition: 0.3s;
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
  &:placeholder-shown {
    color: $color;
  }
  &:focus {
    background-color: white;
    color: #004180;
  }
}

.btn-primary {
  color: #004180;
  background-color: white;
  text-transform: uppercase;
  padding: 15px 20px;
  font-size: 1.4rem;
  border-radius: 0;
  border: 0;
}

@media screen and (max-width: 641px) {
  body,
  html {
    height: auto;
  }
  .spash-bg {
    .logo {
      top: 25%;
      .row {
        height: 50vh;
      }
    }
    .text-container {
      >.row {
        height: 50vh;
      }
    }
  }
}


.lists {
  text-align: center;
  li {
    border: none;        
    text-align: center;
    font-size: 1.4rem;
    color: black;
  }
}

.map {
  height: 680px;
  max-height: 680px;
}

.box {  
  &-single {
    .wrapper {
      position: relative;
      background-color: #DEEDF9;      
      &:after {
        content: '';
        width: 70%;
        background-color: #7CAFDD;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        height: 3px;
      }
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (1 / 1) * 100%;
      }
      > .inner {
        text-align: center;
        position: absolute;
        top: 30%;        
        right: 0;        
        left: 0;
        padding: 0 15px;
        img {
          margin-bottom: 2rem;
          width: 50px;          
          height: 50px;
        }
        p {
          color: black;
          font-weight: 700;
          font-size: 1.4rem;
        }
      }
    }    
  }
}
.navigation {
  position: fixed;  
  z-index: 99999;
  left: 0;
  right: 0;  
  margin: 0 auto;
}
.fixed {
  position: fixed;  
  z-index: 99999;
  left: 0;
  right: 0;  
  margin: 0 auto;
}

.bg-faded {
  background-color: white;
}

.phone {
  // font-weight: 700;
  color: #004180 !important;
  font-size: 1.6rem;
  span {
    font-size: 1.2rem;
    line-height: 1;
  }
}

.nav-link {
  font-size: 1.4rem;  
  cursor: pointer;
  color: #004180 !important;
  font-weight: 400;
  text-transform: uppercase;
  
}

.navbar-toggleable-md .navbar-nav .nav-link {
  padding-right: 2rem;
  padding-left: 2rem;
}

@media screen and (max-width: 991px) { 
  .navbar-collapse {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar-brand.phone {
    margin-right: auto;
    padding-top: 30px;
  }
  .navbar-light .navbar-toggler {
    border-color: #004180;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)'") !important;
  }
  button:hover {
    background: transparent;
    outline: none;
  }
  button:focus {
    outline: none;
  }
  .lg-padding {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md-padding {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

