.xs-padding {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.sm-padding {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.md-padding {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.lg-padding {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.xl-padding {
    padding-top: 13rem;
    padding-bottom: 13rem;
}
