// Colors
.bg-main-yellow {
    background-color: $main-yellow;
}
.bg-main-grey {
    background-color: $main-grey;
}
.bg-secondary-yellow {
    background-color: $secondary-yellow;
}
.bg-secondary-grey {
    background-color: $secondary-grey;
}
.bg-grey {
    background-color: $third-grey;
}
.bg-dark-blue {
    background-color: $dark-blue;
}
