.links a {
  @include border-radius(25px 25px 25px 25px);
  outline: none;
  border: 2px solid $main-yellow;
  padding: 1.2rem 3rem;
  background: none;
  cursor: pointer;
  font-size: 1.8rem;
  display: block;
  text-decoration: none;
  color: $main-grey;
  text-align: center;
  transition: 0.3s;
  margin: 0;
  &:hover {
      background-color: $main-yellow;
      text-decoration: none;
  }
}

button {
  @include border-radius(25px 25px 25px 25px);
  outline: none;
  border: 2px solid $main-yellow;
  padding: 1.2rem 3rem;
  background: none;
  cursor: pointer;
  font-size: 1.8rem;
  display: block;
  text-decoration: none;
  color: $main-grey;
  text-align: center;
  transition: 0.3s;
  margin: 0;
  &:hover {
      background-color: $main-yellow;
      text-decoration: none;
  }
}

.inverted button {
  display: block;
  margin: 0 auto;
  border: 2px solid $main-yellow;  
  background: $main-yellow;      
  &:hover {
      background-color: $main-yellow;
      text-decoration: none;
  }
}

.href-no-style a{
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  border-radius: 0;
  text-align: left;
  &:hover {
    background: none;
  }
}
.back-button {
  .wrapper {
    min-height: 40px;
    display: table;
    width: 100%;
     a {   
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding: 0 3rem 0 3rem;
      position: relative;
      min-width: 205px;          
      background-color: $main-yellow;
      &::after {
        content: url(./../images/arrow_Lb.svg);
        position: absolute;
        top: 58%;
        transform: translateY(-50%);
        left: 10px;
      }
      &:hover {
        background-color: $main-grey;
        color: $main-yellow;
        border-color: $main-grey;
        &::after {
          content: url(./../images/arrow_La.svg);            
        }       
      }        
    }
  } 
}