.inline-list {
    ul {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li {
            display: inline-block;
        }
        li:not(:last-child) {
            margin-right: 5px;
        }
    }
}